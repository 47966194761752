import { Col, Container, Row } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import meter1 from '../assets/img/meter1.svg';
import meter2 from '../assets/img/meter2.svg';
import meter3 from '../assets/img/meter3.svg';
import colorSharp from '../assets/img/color-sharp.png';
import 'animate.css';
import TrackVisibility from 'react-on-screen';


export const Skills = () => {
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

    return (
        <section className='skill' id='our-skills'>
            <Container>
                <Row>
                    <Col>
                    <TrackVisibility once>
                            {({ isVisible }) => (
                                <div className={`animate__animated ${isVisible ? 'animate__slideInLeft' : 'animate__hidden'}`}>
                                    <div className='skill-bx'>
                                        <h2>Skills</h2>
                                        <p>Elevating businesses with seamless Full-Stack Development, dynamic CRM integration, captivating E-Commerce solutions, and cutting-edge SEO strategies. Unleash your online potential with ByteForge today!</p>
                                        <Carousel responsive={responsive} infinite={true} className='skills-slider'>
                                            <div className='item'>
                                                <img src={meter1} alt='meter1' />
                                                <h5>Full-Stack Web Development</h5>
                                            </div>
                                            <div className='item'>
                                                <img src={meter2} alt='meter2' />
                                                <h5>E-Commerce</h5>
                                            </div>  
                                            <div className='item'>
                                                <img src={meter3} alt='meter3' />
                                                <h5>CRM</h5>
                                            </div>  
                                            <div className='item'>
                                                <img src={meter1} alt='meter4' />
                                                <h5>SEO</h5>
                                            </div>    
                                        </Carousel>
                                    </div>
                                </div>
                            )}
                        </TrackVisibility>
                    </Col>
                </Row>
            </Container>
            <img className='background-image-left' src={ colorSharp } alt=''/>
        </section>
    )
}
